import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

function NavBar({ onTabChange }) {
  const handleTabClick = (tabName) => {
    onTabChange(tabName);
  };

  return (
    <Navbar>
        <Nav className="mr-auto">
            <Nav.Link onClick={() => handleTabClick('home')}>Home</Nav.Link>
            <Nav.Link onClick={() => handleTabClick('about')}>About Us</Nav.Link>
            <Nav.Link onClick={() => handleTabClick('features')}>Features</Nav.Link>
            <Nav.Link onClick={() => handleTabClick('finance')}>Finance & Social</Nav.Link>
            {/* <Nav.Link onClick={() => handleTabClick('testimonials')}>Testimonials</Nav.Link> */}
            <Nav.Link onClick={() => handleTabClick('faq')}>FAQ</Nav.Link>
            {/* <Nav.Link onClick={() => handleTabClick('blog')}>Blog</Nav.Link> */}
            <Nav.Link onClick={() => handleTabClick('contact')}>Contact</Nav.Link>
            <Nav.Link onClick={() => handleTabClick('signup')}>Sign Up</Nav.Link>
        </Nav>
    </Navbar>
  );
}

export default NavBar;
