import React from 'react';

function TabContent({ id, active, children }) {
  if (!active) {
    return null;
  }

  return (
    <div id={id} className="tabcontent">
      {children}
    </div>
  );
}

export default TabContent;
