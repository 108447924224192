import React, { useState } from 'react';
import EmailRegistration from '../services/EmailRegistration';

function EmailForm({ onSuccessfulSubmit }) {
  const [email, setEmail] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Add your AJAX request or other logic here
    console.log('Submitting email:', email);
    await EmailRegistration(email);
    onSuccessfulSubmit();
  };

  return (
    <form id="emailForm" onSubmit={handleSubmit}>
      <h3>Enter your email for updates and to join our beta wait list:</h3>
      <input 
        type="email" 
        id="email" 
        name="email" 
        required 
        placeholder="Your email..." 
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
      />
      <input type="submit" value="Subscribe" />
    </form>
  );
}

export default EmailForm;
