import React, { useState } from 'react';

const FAQSection = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = index => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqs = [
        { question: "Can MeTime turn my toddler's 'no' into a 'yes'?", answer: "If only! While it can't negotiate with toddlers (yet), it can help you schedule those much-needed breaks in between parenting marathons." },
        { question: "Will it judge me for having cereal for dinner... again?", answer: "Absolutely not, but it can suggest quick and easy meal ideas for when you're ready to break the cereal streak." },
        { question: "Is MeTime good at giving pep talks on rough parenting days?", answer: "It's like a portable cheerleader! Expect motivational quotes and reminders that you're doing an amazing job." },
        { question: "Can the AI handle my kids' 'why' phase?", answer: "It can provide answers to many 'why' questions, but for the existential ones, you're still the expert!" },
        { question: "Will it remind me to take a moment for myself?", answer: "Absolutely! MeTime is your personal reminder to take a well-deserved break. Self-care is a top priority!" },
        { question: "Can it teach my kids to put their toys away?", answer: "While it can't enforce cleanup time, it can certainly set reminders for it. Maybe hearing it from an AI will do the trick!" },
        { question: "If I ask nicely, will MeTime tell my kids bedtime stories?", answer: "It can suggest some great story ideas and remind you when it's storytime, but your storytelling skills are irreplaceable." },
        { question: "Can it make my coffee before I wake up?", answer: "It can't physically make coffee, but it can sure set a reminder so you never forget to prep the coffee maker the night before." },
        { question: "Will MeTime remind me about the parent-teacher meetings I keep forgetting?", answer: "Consider it done! No more last-minute scrambles to school meetings." },
        { question: "Can it help me find where my child hid my phone... again?", answer: "Direct phone-finding is still in the works, but it can remind you to put your phone in the same place every time to outsmart the little ones!" },
        { question: "Does MeTime come with a built-in wine advisor for tough days?", answer: "While it can't pour you a glass, it can certainly remind you when it's time to unwind and can even suggest the perfect wine based on your taste preferences." },
        { question: "Will it help me mediate sibling arguments?", answer: "It won't take sides, but MeTime can remind you when it's time for everyone to take a breather and suggest activities to calm the storm." },
        // Add other FAQs as needed...
    ];

    return (
        <div className="faq-section">
            <h2>Frequently Asked Questions by Super Moms</h2>
            {faqs.map((faq, index) => (
                <div key={index} className="faq">
                    <button className="accordion" onClick={() => handleClick(index)}>
                        {faq.question}
                    </button>
                    <div className={`panel ${activeIndex === index ? 'open' : ''}`}>
                        <p>{faq.answer}</p>
                    </div>
                    <div className="separator"></div>
                </div>
            ))}
        </div>
    );
};

export default FAQSection;
