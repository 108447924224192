

async function EmailRegistration(email) {
    try {
        const response = await fetch('https://ej4ss69zr2.execute-api.us-east-1.amazonaws.com/Prod/mail', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email: email })
        });
        if (response.ok) {
          // Handle the response
          await response.json();
          //setMessage('Subscription successful!');
        } else {
          throw new Error('Network response was not ok.');
        }
      } catch (error) {
        // Handle errors
        //setMessage('Subscription failed: ' + error.message);
      }
}

export default EmailRegistration