import React from 'react';

function PrivacyPolicy() {

return (
    <div className="privacy-policy-section">
<h2>Privacy Policy for MeTime.bot</h2><br /><br />

<h3>Last Updated: 01/21/2024</h3><br />

Welcome to MeTime.bot ("we", "us", "our"). We respect your privacy and are committed to protecting it through our compliance with this Privacy Policy. This policy describes how we collect, use, maintain, protect, and disclose your information when you use our application, MeTime.bot.<br /><br />

This policy applies to information we collect:<br /><br />

Through our application.<br />
In email, text, and other electronic messages between you and MeTime.bot.<br />
Through mobile and desktop applications you download from MeTime.bot, which provide dedicated non-browser-based interaction between you and MeTime.bot.<br />
1. Information We Collect About You and How We Collect It<br />
We collect several types of information from and about users of our application, including:<br /><br />

Information by which you may be personally identified, such as name, email address, and any other identifier by which you may be contacted online or offline ("personal information").<br />
Information that is about you but individually does not identify you.<br />
Information about your internet connection, the equipment you use to access our application, and usage details.<br />
We collect this information:<br /><br />

Directly from you when you provide it to us.<br />
Automatically as you navigate through the application. Information collected automatically may include usage details, IP addresses, and information collected through cookies and other tracking technologies.<br />
2. How We Use Your Information<br />
We use information that we collect about you or that you provide to us, including any personal information:<br />
<br />
To present our application and its contents to you.<br />
To provide you with information, products, or services that you request from us.<br />
For any other purpose with your consent.<br />
3. Disclosure of Your Information<br />
We may disclose aggregated information about our users, and information that does not identify any individual, without restriction. We do not disclose personal information that we collect or you provide as described in this privacy policy to any third parties.<br />
<br />
4. Data Security<br />
We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.<br />
<br />
5. Changes to Our Privacy Policy<br />
It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users' personal information, we will notify you through a notice on the application.<br />
<br />
6. Contact Information<br />
To ask questions or comment about this privacy policy and our privacy practices, contact us at: <a href='mailto://metime@metime.bot'>metime@metime.bot</a>.<br />
</div>
);
}

export default PrivacyPolicy;