import React from 'react';

function TOS() {

return (
    <div className="tos-section">
        <h2>Terms of Service for MeTime</h2><br />
        <br />
<h3>Last Updated: 01/21/2024</h3><br />
<br />
Welcome to MeTime<br />
<br />
MeTime is a service provided by Eldritch, Inc. ("Eldritch"), aimed at managing your emails, calendars, school assignments, bills, and other personal tasks through advanced AI technology. MeTime is accessible at https://metime.bot.<br />
<br />
1. Acceptance of Terms<br />
<br />
By accessing or using MeTime, you ("User") agree to be bound by these terms and conditions. If you do not agree, please refrain from using MeTime.<br />
<br />
2. Privacy and Data Usage<br />
<br />
2.1 Data Collection: MeTime collects personal data necessary to provide its services. This includes, but is not limited to, emails, calendar entries, school assignments, and billing information.<br />
2.2 Use of Data: Data is used to personalize and improve our services. We commit to using your data responsibly and ethically.<br />
2.3 Data Protection: We adhere to stringent data protection standards, in compliance with GDPR, HIPAA, CCPA, and other relevant laws.<br />
2.4 User Consent: By using MeTime, you consent to the collection and use of your data as described in our Privacy Policy (https://metime.bot/privacy).<br />
3. License and Restrictions<br />
<br />
3.1 License Grant: Eldritch grants you a non-exclusive, non-transferable license to use MeTime for your personal, non-commercial purposes.<br />
3.2 Usage Restrictions: You agree not to use MeTime for any unlawful purposes or in a manner that violates these terms.<br />
4. User Responsibilities<br />
<br />
4.1 Account Security: You are responsible for maintaining the security of your account.<br />
4.2 Accurate Information: You agree to provide accurate and current information to MeTime.<br />
5. Limitation of Liability<br />
<br />
Eldritch is not liable for indirect, incidental, special, consequential, or punitive damages resulting from your use of MeTime, except where prohibited by law.<br />
<br />
6. Governing Law & Jurisdiction<br />
<br />
These terms are governed by the laws of the state of Missouri, and any disputes will be resolved in its courts.<br />
<br />
7. Changes to Terms<br />
<br />
We reserve the right to modify these terms and will provide notice of significant changes.<br />
<br />
8. User Rights<br />
<br />
8.1 Data Access and Control: You have the right to access, edit, and delete your data.<br />
8.2 Opt-Out Options: Users can opt out of specific data collection and processing activities.<br />
9. Contact Information<br />
<br />
For questions or concerns about these terms, please contact us at metime@metime.bot.<br />
</div>
);
}

export default TOS;